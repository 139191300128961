import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import DrupalNodeBody from "../components/drupal-node-body"

const ArticlePage = ({ data }) => ( 
  	<Layout>
    	    <article>
      	        <h1>{data.nodeArticle.title}</h1>
		<DrupalNodeBody relatedNode={data.nodeArticle}  />
    	    </article>
  	</Layout>
);

export default ArticlePage

export const query = graphql`
  query($slug: String!) {
    nodeArticle (fields: { slug: { eq: $slug } }) {
      title
      created
      changed
      body {
        processed
        summary
      }
    }
  }
`
